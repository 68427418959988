import AnimateItem from '@/components/atoms/AnimateItem/AnimateItem';
import Icon from '@/components/atoms/Icon/Icon';
import ButtonWithIcon from '@/components/molecules/ButtonWithIcon/ButtonWithIcon';
import cdnImageLoader from '@/lib/loaders/cdnImageLoader';
import { Dialog, Transition } from '@headlessui/react';
import Image, { ImageLoader } from 'next/legacy/image';
import { Fragment, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player/youtube';

/**
 * IVideoPlayer Interface for the Video Player
 *
 * @interface IVideoPlayer
 */
export interface IVideoPlayer {
  /**
   * The title to Display
   *
   * @memberof IVideoPlayer
   * @member {string} title
   */
  title: string;
  /**
   * Image URL
   *
   * @memberof IVideoPlayer
   * @member {string} image
   */
  image: string;
  /**
   * Alt Text for the Image placeholder on the video
   *
   * @memberof IVideoPlayer
   * @member {string} alt
   */
  alt: string;
  /**
   * Video URL to Youtube
   *
   * @memberof IVideoPlayer
   * @member {string} video
   */
  video: string;
  /**
   * The optional classes
   *
   * @memberof IVideoPlayer
   * @default ''
   * @member {string} [classes]
   */
  classes?: string;
  /**
   * Whether to start the video muted or unmuted
   *
   * @memberof IVideoPlayer
   * @default true
   * @member {boolean} [muted]
   */
  muted?: boolean;
  /**
   * The loader function for the image
   *
   * @memberof IVideoPlayer
   * @member {ImageLoader} [loader]
   */
  loader?: ImageLoader;
}

/**
 * Video Player The Video Player component that displays a video with a title
 * and image.
 *
 * @param {IVideoPlayer} props - The props of the Video Player component.
 * @returns {React.FC<IVideoPlayer>} Video Player Component
 */
const VideoPlayer: React.FC<IVideoPlayer> = ({
  title,
  image,
  alt,
  video,
  classes = '',
  muted = true,
  loader,
}: IVideoPlayer) => {
  const [isOpen, setIsOpen] = useState(false);
  const [screenPosition, setScreenPosition] = useState(0);
  const playerWrapperRef = useRef(null);

  useEffect(() => {
    const element = document.getElementById('video-player-image');
    setScreenPosition(element?.getBoundingClientRect().top || 0);
  }, [screenPosition]);

  /** Close Modal */
  const closeModal = () => {
    window.scrollTo({ top: screenPosition, behavior: 'auto' });
    setIsOpen(false);
  };

  /** Open Modal */
  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <div className={`${classes} z-[1]`}>
      <AnimateItem from={{ translateY: 50 }} delay={0.4}>
        <div data-testid="video-player">
          <div
            className="flex justify-center items-center h-full relative aspect-video overflow-hidden rounded-[20px] sm:rounded-3xl"
            id="video-player-image"
          >
            {!isOpen && (
              <button
                onClick={openModal}
                className="flex items-center justify-center cursor-pointer z-[1] rounded focus-visible:outline-focus-400 focus-visible:outline-2 focus-visible:outline"
                data-testid="video-player-modal-open"
              >
                <Icon
                  size={40}
                  icon="playCircle"
                  colorType="neutral"
                  colorShade={100}
                  classes="mr-2"
                />
                <span className="text-neutral-100 text-h5 font-petco font-bold">
                  {title}
                </span>
              </button>
            )}
            <Image
              src={image}
              alt={alt}
              layout="fill"
              className="object-fit aspect-video -z-10"
              sizes="(max-width: 1439px) 100vw, 1400px"
              loader={loader || cdnImageLoader}
            />

            <div className="bg-black/30 absolute top-0 left-0 h-full w-full" />
          </div>
          <Transition appear show={isOpen} as={Fragment}>
            <Dialog
              as="div"
              className="z-[30] bg-neutral-100 h-screen w-screen flex justify-center items-center fixed inset-0 overflow-hidden"
              onClose={closeModal}
              initialFocus={playerWrapperRef}
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-neutral-100 " />
              </Transition.Child>

              <div className="absolute inset-0 overflow-y-auto w-full z-50">
                <div className="flex min-h-full">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full h-full transform overflow-hidden transition-all z-100">
                      <div className="flex justify-center items-center aspect-video flex-col bg-neutral-100 p-6 w-screen h-screen">
                        <div className="flex flex-col max-w-full w-[327px] sm:w-[680px] md:w-[1128px] px-6 sm:px-8 md:px-10 aspect-video relative -mt-10">
                          <div className="flex justify-end w-full ">
                            <ButtonWithIcon
                              icon={{
                                icon: 'close',
                                colorShade: 800,
                                colorType: 'neutral',
                                size: 24,
                              }}
                              onClick={closeModal}
                              classes="mb-5"
                            >
                              Close
                            </ButtonWithIcon>
                          </div>
                          <div ref={playerWrapperRef}>
                            <ReactPlayer
                              playing={true}
                              playsInline={true}
                              url={video}
                              controls={true}
                              muted={muted}
                              stopOnUnmount={true}
                              volume={1}
                              width="100%"
                              height="100%"
                              className="aspect-video"
                              data-testid="video-player-react-player"
                            />
                          </div>
                        </div>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </AnimateItem>
    </div>
  );
};

export default VideoPlayer;
