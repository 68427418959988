import cdnSquareImageLoader from '@/lib/loaders/cdnSquareImageLoader';
import Image, { ImageProps } from "next/legacy/image";

/**
 * CdnImage An image from the CDN
 *
 * @param {ImageProps} props - The props for the CdnImage component
 * @returns {React.FC<ImageProps>} Component
 */
const CdnSquareImage: React.FC<ImageProps> = (props: ImageProps) => {
  return (
    <Image
      data-testid={'cdn-square-image'}
      loader={cdnSquareImageLoader}
      {...props}
      alt={props.alt}
    />
  );
};

export default CdnSquareImage;
